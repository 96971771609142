import { StorageEmitter } from 'utils/storage-emitter'
import { storage } from 'utils/storage'
import { WCODE_KEY } from 'const/labels'
import { SessionStorageEmitter } from 'utils/session-storage-emitter'

const EXPIRATION_OFFSET = 1000 * 30 // subtract 30 seconds from expiration date - because network requests have some timeout

export class AuthStorage extends StorageEmitter {
  get auth() {
    return this.value
  }

  set auth(value) {
    this.value = value
  }

  get accessToken() {
    return this.value?.accessToken
  }
  get refreshToken() {
    return this.value?.refreshToken
  }

  set value(value) {
    super.value = value
  }

  get value() {
    const value = super.value
    if (value && isExpired(value.refreshTokenExpiresAt)) {
      storage.write(this.storageKey, null)
      return null
    }
    return value
  }

  /** @override */
  notifyListeners() {
    const isAuthenticated = this.isAuthenticated()
    this._listeners.forEach((listener) => listener(isAuthenticated))
  }

  isAuthenticated() {
    const { accessToken, refreshTokenExpiresAt } = this.value || {}
    return !!accessToken && !isExpired(refreshTokenExpiresAt)
  }

  isFullAuthenticated() {
    const { full } = this.value || {}
    return this.isAuthenticated() && full
  }

  isConfirmed() {
    const { confirmed } = this.value || {}
    return confirmed
  }

  isAccessTokenExpired() {
    const { accessTokenExpiresAt } = this.value || {}
    if (!accessTokenExpiresAt) throw 'missing accessTokenExpiresAt'
    return isExpired(accessTokenExpiresAt)
  }

  getHeaders() {
    const { accessToken } = this.value || {}
    return accessToken ? { 'X-Auth-Token': `Bearer ${accessToken}` } : {}
  }

  getExpirationTime() {
    return this.isAuthenticated()
      ? new Date(this.value.refreshTokenExpiresAt).valueOf() - EXPIRATION_OFFSET
      : null
  }
}

export const isExpired = (date) => {
  const now = Date.now().valueOf() + EXPIRATION_OFFSET // + {EXPIRATION_OFFSET} ms
  return new Date(date).valueOf() <= now
}

export const authStorage = new AuthStorage('auth')

export const userStorage = new StorageEmitter('user')

export const wcodeStorage = new StorageEmitter(WCODE_KEY)
export const wcodeEnableSavingStorage = new StorageEmitter('enable_wcode_saving')
export const unauthorizedLogOutStorage = new StorageEmitter('unauthorized_log_out')

authStorage.addListener((isAuthenticated) => {
  if (!isAuthenticated) {
    userStorage.value = null
  }
})

//Session storage

export const listFilters = new SessionStorageEmitter('list_filters')

export const useSessionStorageExist = (key) => sessionStorage.getItem(key) !== null
